import React from 'react';
import './app.css';
import './animations.css'

import {Route, Switch, BrowserRouter} from 'react-router-dom';
import {AuthRoute, ProtectedRoute } from '../util/route_util';
import LandingPage from './landing_page_container';


function App() {
  return (
    <div className="">
      <header>      

      </header>
        <Switch>
          <Route exact path='/' component={LandingPage} /> 

        </Switch>


    </div>
  );
}

export default App;
